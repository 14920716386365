import useGetApi from '@dg/common/lib/hooks/useGetApi'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import {
	bottomInfoApi, BottomInfoApiProps
} from '@dg/common/lib/mobile/api'
import IcNew from '@dg/common/public/icons/mobile/common/ic-new.svg'
import {
	UseQueryResult
} from '@tanstack/react-query'
import Image from 'next/image'
import {
	PropsWithChildren
} from 'react'

interface FooterTabAsyncLiViewProps {
	type: `myinfo` | `viewed` | `wish`;
}

const FooterTabAsyncLiView = ({
	type
}: PropsWithChildren<FooterTabAsyncLiViewProps>) => {
	const imageValidate = useImageValidate()

	const bottomInfoDataApi = useGetApi({
		api: bottomInfoApi,
		name: `bottomInfoDataApi`
	}) as UseQueryResult<BottomInfoApiProps>

	const wishCnt = bottomInfoDataApi.data?.result.wish_count

	const viewedimg =
		bottomInfoDataApi.data?.result.recent_view_goods !== null ?
			bottomInfoDataApi.data?.result.recent_view_goods[0].img_src :
			undefined

	const wishChk = type === `wish` && wishCnt !== undefined && wishCnt > 0

	const myInfoChk =
		type === `myinfo` && bottomInfoDataApi.data !== undefined && bottomInfoDataApi.data.result.message_count > 0

	const viewedChk = type === `viewed` && viewedimg !== undefined

	return (
		<>
			{
				(wishChk === true || myInfoChk === true) && (
					<span
						className={
							`
								absolute left-1/2 top-1.5 ml-3 flex h-3 -translate-x-1/2
								items-center justify-center rounded-md bg-dg-black
								text-3xs text-dg-white dark:bg-dgdark-black dark:text-dgdark-white
								${type === `wish` ? `min-w-3 px-1` : `w-3`}
							`
						}
					>
						{
							type === `wish` && (
								wishCnt !== undefined && wishCnt > 99 ? `99+` : wishCnt
							)
						}

						{
							type === `myinfo` && (
								<>
									<IcNew
										className="h-2.5"
									/>

									<span
										className="sr-only"
									>
										New
									</span>
								</>
							)
						}
					</span>
				)
			}

			{
				viewedChk === true && (
					<div
						className="absolute left-1/2 top-1/2 -mt-2 size-7 -translate-x-1/2 -translate-y-1/2 rounded-sm
							border border-solid border-dg-400 bg-dg-white dark:border-dgdark-400 dark:bg-dgdark-white"
					>
						<Image
							alt="Viewed Image"
							className="object-cover"
							onError={imageValidate.onError}
							sizes="26px"
							src={viewedimg || ``}
							fill
							priority
						/>
					</div>
				)
			}
		</>)
}

export default FooterTabAsyncLiView
