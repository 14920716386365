import LayerpopupView from '@dg/common/components/Layerpopup/LayerpopupView/LayerpopupView'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	appRefreshFunc
} from '@dg/common/lib/qoo10Common'
import {
	layerpopupData
} from '@dg/common/lib/store'
import {
	useAtom
} from 'jotai'
import React, {
	PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState
} from 'react'

interface LayerpopupProps {
	backBtn?: boolean;
	bottom?: boolean;
	className?: string;
	closeBtn?: boolean;
	closeEventFunc?: () => void;
	customFooter?: ReactNode;
	defaultFooter?: boolean;
	defaultFooterEventFunc?: (event: React.MouseEvent) => void;
	defaultOpen?: boolean;
	disableDrag?: boolean;
	focusDuration?: number;
	focusValue?: number;
	full?: boolean;
	height?: string;
	id: string;
	maxHeight?: string;
	notClose?: boolean;
	overflow?: boolean;
	setTitle?: ReactNode;
	setWidth?: boolean;
	width?: string;
	wrapZIndex?: string;
}

const Layerpopup = ({
	children,
	id,
	className = ``,
	backBtn = false,
	bottom = false,
	customFooter,
	focusDuration = 0,
	focusValue = 0,
	full = false,
	setWidth = true,
	notClose = false,
	overflow = false,
	setTitle,
	closeBtn = false,
	defaultFooter = false,
	wrapZIndex = `z-150`,
	width = `w-max`,
	height = `h-auto`,
	maxHeight = `max-h-9/10`,
	defaultOpen = false,
	disableDrag = false,
	closeEventFunc,
	defaultFooterEventFunc
}: PropsWithChildren<LayerpopupProps>) => {
	const layerpopupHooks = useLayerpopup()

	const [
		storeLayerpopup,
		setStoreLayerpopup
	] = useAtom(layerpopupData)

	const layerpopupRef = useRef<HTMLDivElement>(null)

	if (storeLayerpopup[id] === undefined) {
		storeLayerpopup[id] = {
			callbackFunc: undefined,
			id,
			open: defaultOpen,
			text: ``,
			title: ``,
			transition: true
		}
	}

	const {
		open, transition, callbackFunc
	} = storeLayerpopup[id]

	let {
		title, text
	} = storeLayerpopup[id]

	if (title === `` && setTitle !== undefined) {
		title = setTitle
	}

	if (text === `` && children !== undefined) {
		text = children
	}

	const data = useMemo(() => ({
		animationFrame: 0,
		animationFrameEffectX: 0,
		animationFrameEffectY: 0,
		chkFlag: false,
		delayFunc: null as unknown as ReturnType<typeof setTimeout>,
		direction: ``,
		moveY: 0,
		openCloseDelayFunc: null as unknown as ReturnType<typeof setTimeout>,
		openDelayFunc: null as unknown as ReturnType<typeof setTimeout>,
		oriHeight: 0,
		startX: 0,
		startY: 0,
		timeStamp: 0
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		wrapHidden: true,
		wrapOpacity: true
	})

	const cancelAnimationFunc = useCallback(() => window.cancelAnimationFrame(data.animationFrame), [
		data
	])

	const scrollXYToFunc = useCallback((fromX: number, fromY: number, toX: number, toY: number, duration = 300) => {
		const layerpopup = {
			content: layerpopupRef.current?.querySelector(`.layerpopup-content`) as HTMLDivElement
		}

		const {
			content
		} = layerpopup

		const translateData = {
			changeX: toX - fromX,
			changeY: toY - fromY,
			currentTime: 0,
			increment: 20,
			startX: fromX,
			startY: fromY
		}

		const {
			changeX, changeY, increment, startX, startY
		} = translateData

		let {
			currentTime
		} = translateData

		// t = current time
		// b = start value
		// c = change in value
		// d = duration
		const easeOutQuad = (t: number, b: number, c: number, d: number) => {
			let calcT = t

			calcT /= d

			const easeCalc1 = -c * calcT
			const easeCalc2 = easeCalc1 * (calcT - 2)
			const easeCalcValue = easeCalc2 + b

			return easeCalcValue
		}

		const animateScroll = () => {
			currentTime += increment

			data.animationFrameEffectX = easeOutQuad(currentTime, startX, changeX, duration)
			data.animationFrameEffectY = easeOutQuad(currentTime, startY, changeY, duration)

			content.scrollTo(data.animationFrameEffectX, data.animationFrameEffectY)

			if (currentTime < duration) {
				data.animationFrame = window.requestAnimationFrame(animateScroll)
			}
		}

		if (duration >= 100) {
			animateScroll()
		} else {
			content.scrollTo(toX, toY)
		}
	}, [
		data
	])

	const getScrollParentFunc = useCallback((node: HTMLElement): {
		obj?: HTMLElement;
		status?: string;
	} => {
		const scrollChk1 =
			window.getComputedStyle(node).overflowX !== `hidden` &&
			window.getComputedStyle(node).overflowX !== `visible`

		const scrollChk2 =
			window.getComputedStyle(node).overflowY !== `hidden` &&
			window.getComputedStyle(node).overflowY !== `visible`

		if (scrollChk1 || scrollChk2) {
			const scrollValue = `
				${node.scrollWidth > node.offsetWidth ? `x` : ``}
				${node.scrollHeight > node.offsetHeight ? `y` : ``}
			`.trim().replace(/\n\s+/gim, ` `)

			return {
				obj: node,
				status: `scroll${scrollValue}`
			}
		}

		if (
			node.parentNode === null ||
			node.parentNode === document ||
			(node.parentNode as HTMLElement).classList.contains(`layerpopup-content`) === true
		) {
			return {
				obj: null as unknown as HTMLElement,
				status: `none`
			}
		}

		return getScrollParentFunc(node.parentElement as HTMLElement)
	}, [])

	const layerpopupHoldFunc = useCallback(() => {
		const layerpopup = {
			body: document.querySelector(`body`) as HTMLElement,
			wrap: document.querySelector(`#wrap`) as HTMLDivElement
		}

		const {
			wrap, body
		} = layerpopup

		const scrollY = wrap.scrollTop > 0 ? wrap.scrollTop : window.scrollY;

		(document.querySelector(`html`) as HTMLHtmlElement).style.overscrollBehaviorY = `none`

		body.style.overflow = `hidden`

		wrap.setAttribute(`data-scrolltop`, `${scrollY}`)

		window.scrollTo(0, scrollY)

		appRefreshFunc(false)
	}, [])

	const layerpopupReleaseFunc = useCallback(() => {
		const layerpopup = {
			body: document.querySelector(`body`) as HTMLElement,
			wrap: document.querySelector(`#wrap`) as HTMLDivElement
		}

		const {
			wrap, body
		} = layerpopup

		const wrapScrollTop = wrap.getAttribute(`data-scrolltop`)

		const scrollY =
			wrap.getAttribute(`data-scrolltop`) !== null ? parseFloat(wrapScrollTop ?? `0`) : window.scrollY

		body.style.overflow = ``;

		(document.querySelector(`html`) as HTMLHtmlElement).style.overscrollBehaviorY = ``

		wrap.removeAttribute(`data-scrolltop`)

		window.scrollTo(0, scrollY)

		appRefreshFunc(true)
	}, [])

	const layerpopupOpenFunc = useCallback(() => {
		const layerpopup = {
			layerpopupBg: layerpopupRef.current?.nextElementSibling as HTMLDivElement
		}

		const {
			layerpopupBg
		} = layerpopup

		data.animationFrame = 0
		data.animationFrameEffectX = 0
		data.animationFrameEffectY = 0

		clearTimeout(data.openCloseDelayFunc)
		clearTimeout(data.openDelayFunc)

		setPageData({
			wrapHidden: false,
			wrapOpacity: true
		})

		const duration = transition === true ? 100 : 0

		data.openDelayFunc = setTimeout(() => {
			setPageData({
				wrapHidden: false,
				wrapOpacity: false
			})

			data.openCloseDelayFunc = setTimeout(() => {
				if (window.getComputedStyle(layerpopupBg).display !== `none`) {
					layerpopupHoldFunc()
				}
			}, duration)
		}, transition === true ? 10 : 0)
	}, [
		data,
		layerpopupHoldFunc,
		transition
	])

	const layerpopupCloseFunc = useCallback(() => {
		const layerpopup = {
			obj: layerpopupRef.current as HTMLDivElement
		}

		const {
			obj
		} = layerpopup

		setPageData({
			wrapHidden: false,
			wrapOpacity: true
		})

		const durationSec = parseFloat(window.getComputedStyle(obj).transitionDuration) * 1000
		const duration = transition === true ? durationSec + 50 : 0

		clearTimeout(data.openCloseDelayFunc)
		clearTimeout(data.openDelayFunc)

		data.openCloseDelayFunc = setTimeout(() => {
			setPageData({
				wrapHidden: true,
				wrapOpacity: true
			})

			if (document.querySelectorAll(`.layerpopup-wrap:not(.invisible)`).length === 1) {
				layerpopupReleaseFunc()
			}

			const setData = {
				...storeLayerpopup
			}

			if (setData[id].open === true) {
				setData[id] = {
					...setData[id],
					open: false
				}

				setStoreLayerpopup(setData)
			}

			if (closeEventFunc !== undefined) {
				closeEventFunc()
			}
		}, duration)
	}, [
		closeEventFunc,
		data,
		id,
		layerpopupReleaseFunc,
		setStoreLayerpopup,
		storeLayerpopup,
		transition
	])

	const btnCloseFunc = useCallback((event?: Event | React.MouseEvent) => {
		const obj = event?.target as HTMLElement

		const closeChk = obj?.classList.contains(`btn-close`)

		if (notClose === false || closeChk === true) {
			layerpopupCloseFunc()
		} else if (closeEventFunc !== undefined) {
			closeEventFunc()
		}

		if (callbackFunc !== undefined) {
			callbackFunc()
		}

		if (obj) {
			if (defaultFooterEventFunc !== undefined && obj?.closest(`.layerpopup-footer`) !== null) {
				defaultFooterEventFunc(event as React.MouseEvent)
			}
		}
	}, [
		callbackFunc,
		closeEventFunc,
		defaultFooterEventFunc,
		layerpopupCloseFunc,
		notClose
	])

	/* LAYERPOPUP BACKGROUND EVENT */
	const layerpopupBgFunc = useCallback((event: MouseEvent | TouchEvent) => {
		if (typeof TouchEvent !== `undefined` && event instanceof TouchEvent && event.type === `touchstart`) {
			if (event.cancelable === true) {
				event.preventDefault()
				event.stopPropagation()
			}
		} else if (notClose === false) {
			const focusTarget = layerpopupRef.current?.querySelector(`:focus`) as HTMLInputElement

			if (focusTarget !== null) {
				focusTarget.blur()
			}

			btnCloseFunc()
		}
	}, [
		btnCloseFunc,
		notClose
	])

	const layerpopupFocusFunc = useCallback((value: number, duration: number) => {
		const layerpopup = {
			content: layerpopupRef.current?.querySelector(`.layerpopup-content`) as HTMLDivElement
		}

		const {
			content
		} = layerpopup

		cancelAnimationFunc()
		scrollXYToFunc(0, content.scrollTop, 0, value, duration)
	}, [
		cancelAnimationFunc,
		scrollXYToFunc
	])

	/* LAYERPOPUP HEADER EVENT */
	const layerpopupHeaderFunc = useCallback((event: TouchEvent) => {
		const layerpopup = {
			body: document.querySelector(`body`) as HTMLElement,
			header: layerpopupRef.current?.querySelector(`.layerpopup-header`) as HTMLDivElement,
			obj: layerpopupRef.current as HTMLDivElement
		}

		const {
			header, obj, body
		} = layerpopup

		const duration = 300

		if (event.touches.length < 2) {
			if (event.type === `touchstart`) {
				body.style.overscrollBehavior = `contain`

				data.timeStamp = new Date().getTime()
				data.oriHeight = obj.offsetHeight
				data.startY = event.touches[0].clientY
				data.moveY = 0
			} else if (event.type === `touchmove`) {
				if (event.cancelable === true) {
					event.preventDefault()
					event.stopPropagation()
				}

				data.moveY = event.touches[0].clientY - data.startY

				obj.style.height = `${data.oriHeight - data.moveY}px`
			} else if (event.type === `touchend`) {
				const timestampEnd = new Date().getTime() - data.timeStamp

				const touchEndChk1 =
					(event.target as HTMLElement).closest(`.btn-close`) !== null && timestampEnd <= duration

				const touchEndChk2 =
					notClose === false &&
					data.moveY > 0 &&
					(timestampEnd <= duration || data.oriHeight - data.moveY <= header.offsetHeight)

				if (touchEndChk1 || touchEndChk2) {
					layerpopupCloseFunc()
				}

				body.style.overscrollBehavior = ``
			}
		}
	}, [
		layerpopupCloseFunc,
		data,
		notClose
	])

	/* LAYERPOPUP CONTENT EVENT */
	const layerpopupContentFunc = useCallback((event: TouchEvent | WheelEvent) => {
		const layerpopup = {
			body: document.querySelector(`body`) as HTMLElement,
			content: layerpopupRef.current?.querySelector(`.layerpopup-content`) as HTMLDivElement
		}

		const {
			content, body
		} = layerpopup

		const chkScroll = (chkObj: HTMLElement, moveValue: number) => {
			const scrollChk1 = moveValue > 0 && chkObj.scrollTop - moveValue < 0 && content.scrollTop <= 0

			const scrollChk2 = moveValue < 0 &&
				chkObj.scrollTop + chkObj.offsetHeight - moveValue > chkObj.scrollHeight &&
				content.scrollTop + content.offsetHeight >= content.scrollHeight

			if (data.chkFlag === true || scrollChk1 || scrollChk2) {
				data.chkFlag = true

				if (event.cancelable === true) {
					event.preventDefault()
					event.stopPropagation()
				}
			}
		}

		const moveFunc = (evt: TouchEvent | WheelEvent) => {
			if (data.direction === ``) {
				data.direction = `vertical`

				if (typeof TouchEvent !== `undefined` && evt instanceof TouchEvent) {
					if (
						evt.type === `touchmove` &&
						Math.abs(data.startX - evt.touches[0].clientX) >
						Math.abs(data.startY - evt.touches[0].clientY)
					) {
						data.direction = `horizontal`
					}
				} else if (typeof WheelEvent !== `undefined` && evt instanceof WheelEvent) {
					if (evt.type === `wheel` && Math.abs(evt.deltaX) > Math.abs(evt.deltaY)) {
						data.direction = `horizontal`
					}
				}
			}

			let chkValue = 0

			if (typeof TouchEvent !== `undefined` && evt instanceof TouchEvent) {
				if (evt.type === `touchmove`) {
					data.moveY = evt.touches[0].clientY - data.startY

					chkValue = data.moveY
				}
			} else if (typeof WheelEvent !== `undefined` && evt instanceof WheelEvent) {
				if (evt.type === `wheel`) {
					data.moveY = 0

					chkValue = -(evt.deltaY / Math.abs(evt.deltaY))
				}
			}

			const evtTarget = evt.target as HTMLElement

			if (data.direction === `vertical`) {
				const obj =
					(
						getScrollParentFunc(evtTarget)?.status === `none` ||
						getScrollParentFunc(evtTarget)?.status === `scrollx` ?
							content :
							getScrollParentFunc(evtTarget)?.obj
					) as HTMLElement

				if (typeof TouchEvent !== `undefined` && evt instanceof TouchEvent) {
					chkScroll(obj, chkValue)
				}
			} else if (data.direction === `horizontal`) {
				if (getScrollParentFunc(evtTarget)?.status === `none`) {
					if (evt.cancelable === true) {
						evt.preventDefault()
						evt.stopPropagation()
					}
				}
			}
		}

		if (typeof TouchEvent !== `undefined` && event instanceof TouchEvent) {
			if (event.touches.length < 2) {
				if (event.type === `touchstart`) {
					body.style.overscrollBehavior = `contain`

					data.startX = event.touches[0].clientX
					data.startY = event.touches[0].clientY
					data.chkFlag = false
					data.direction = ``
				} else if (event.type === `touchmove`) {
					moveFunc(event)
				} else if (event.type === `touchend`) {
					body.style.overscrollBehavior = ``
				}
			}
		} else if (typeof WheelEvent !== `undefined` && event instanceof WheelEvent) {
			if (event.type === `wheel`) {
				moveFunc(event)

				clearTimeout(data.delayFunc)
				data.delayFunc = setTimeout(() => {
					data.chkFlag = false
					data.direction = ``
				}, 100)
			}
		}
	}, [
		getScrollParentFunc,
		data
	])

	useEffect(() => {
		if (layerpopupRef.current !== null) {
			if (layerpopupHooks.get()[id] === undefined) {
				layerpopupHooks.set({
					id
				})
			}

			if (open === true && pageData.wrapHidden === true && pageData.wrapOpacity === true) {
				layerpopupOpenFunc()

				if (focusValue !== undefined) {
					layerpopupFocusFunc(focusValue, focusDuration ?? 300)
				}
			} else if (
				defaultOpen === false &&
				open === false &&
				pageData.wrapHidden === false &&
				pageData.wrapOpacity === false
			) {
				layerpopupCloseFunc()
			}

			const layerpopupHeader = layerpopupRef.current.querySelector(`.layerpopup-header`) as HTMLDivElement

			if (disableDrag === false && bottom === true && layerpopupHeader !== null) {
				layerpopupHeader.addEventListener(`touchstart`, layerpopupHeaderFunc)
				layerpopupHeader.addEventListener(`touchmove`, layerpopupHeaderFunc)
				layerpopupHeader.addEventListener(`touchend`, layerpopupHeaderFunc)
			}

			const layerpopupContent = layerpopupRef.current.querySelector(`.layerpopup-content`) as HTMLDivElement

			layerpopupContent.addEventListener(`touchstart`, layerpopupContentFunc)
			layerpopupContent.addEventListener(`touchmove`, layerpopupContentFunc)
			layerpopupContent.addEventListener(`touchend`, layerpopupContentFunc)
			layerpopupContent.addEventListener(`wheel`, layerpopupContentFunc)

			const customBtnCloseArray =
				Array.from(layerpopupRef.current.querySelectorAll(`.btn-close`))
					.filter((item) => item.getAttribute(`data-original`) !== `close`)

			for (const item of customBtnCloseArray) {
				item.addEventListener(`click`, btnCloseFunc)
			}

			const layerpopupBg = layerpopupRef.current.nextElementSibling as HTMLDivElement

			layerpopupBg.addEventListener(`click`, layerpopupBgFunc)
			layerpopupBg.addEventListener(`touchstart`, layerpopupBgFunc)
			layerpopupBg.addEventListener(`touchend`, layerpopupBgFunc)
		}

		const cleanup = () => {
			document.body.style.overflow = ``

			if (layerpopupRef.current !== null) {
				const layerpopupHeader = layerpopupRef.current.querySelector(`.layerpopup-header`) as HTMLDivElement

				if (disableDrag === false && bottom === true && layerpopupHeader !== null) {
					layerpopupHeader.removeEventListener(`touchstart`, layerpopupHeaderFunc)
					layerpopupHeader.removeEventListener(`touchmove`, layerpopupHeaderFunc)
					layerpopupHeader.removeEventListener(`touchend`, layerpopupHeaderFunc)
				}

				const layerpopupContent = layerpopupRef.current.querySelector(`.layerpopup-content`) as HTMLDivElement

				layerpopupContent.removeEventListener(`touchstart`, layerpopupContentFunc)
				layerpopupContent.removeEventListener(`touchmove`, layerpopupContentFunc)
				layerpopupContent.removeEventListener(`touchend`, layerpopupContentFunc)
				layerpopupContent.removeEventListener(`wheel`, layerpopupContentFunc)

				const customBtnCloseArray =
					Array.from(layerpopupRef.current.querySelectorAll(`.btn-close`))
						.filter((item) => item.getAttribute(`data-original`) !== `close`)

				for (const item of customBtnCloseArray) {
					item.removeEventListener(`click`, btnCloseFunc)
				}

				const layerpopupBg = layerpopupRef.current.nextElementSibling as HTMLDivElement

				layerpopupBg.removeEventListener(`click`, layerpopupBgFunc)
				layerpopupBg.removeEventListener(`touchstart`, layerpopupBgFunc)
				layerpopupBg.removeEventListener(`touchend`, layerpopupBgFunc)
			}
		}

		return cleanup
	}, [
		bottom,
		btnCloseFunc,
		defaultOpen,
		disableDrag,
		focusDuration,
		focusValue,
		id,
		layerpopupBgFunc,
		layerpopupCloseFunc,
		layerpopupContentFunc,
		layerpopupFocusFunc,
		layerpopupHeaderFunc,
		layerpopupHooks,
		layerpopupOpenFunc,
		open,
		pageData.wrapHidden,
		pageData.wrapOpacity
	])

	const props = {
		backBtn,
		bottom,
		btnCloseFunc,
		className,
		closeBtn,
		customFooter,
		defaultFooter,
		full,
		height,
		id,
		layerpopupRef,
		maxHeight,
		notClose,
		overflow,
		setWidth,
		text,
		title,
		transition,
		width,
		wrapHidden: pageData.wrapHidden,
		wrapOpacity: pageData.wrapOpacity,
		wrapZIndex
	}

	return (
		<LayerpopupView
			{...props}
		/>
	)
}

export default Layerpopup
