import {
	device
} from '@dg/common/lib/common'
import useLoading from '@dg/common/lib/hooks/useLoading'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	MutationFunction, useMutation
} from '@tanstack/react-query'
import {
	AxiosError
} from 'axios'
import {
	useRouter
} from 'next/router'
import {
	useCallback, useMemo
} from 'react'

interface UseSetApiProps {
	api: MutationFunction<unknown, never>;
	errorCallback?: (
		error: AxiosError | string,
		variables: never
	) => void;
	mutateCallback?: (
		variables?: URLSearchParams
	) => void;
	name?: string;
	progress?: boolean;
	successCallback?: (
		successData: never,
		variables: never
	) => void;
}

const useSetApi = ({
	api, mutateCallback, successCallback, errorCallback, progress = false, name
}: UseSetApiProps) => {
	const data = useMemo(() => ({
		delayFunc: null as unknown as ReturnType<typeof setTimeout>,
		flag: false
	}), [])

	const router = useRouter()
	const loading = useLoading()

	const useSetApiMutation = useMutation({
		mutationFn: api as MutationFunction<never, never>,
		mutationKey: [
			`${name}`
		],
		onError: (error: AxiosError, variables: never) => {
			if (typeof Error !== `undefined` && process.env.NODE_ENV === `development`) {
				console.log(error)
			}

			if (progress === true) {
				loading.close()
			}

			if (error.response?.status === 401 && errorCallback === undefined) {
				const pathDataChk = device().browser.desktop === undefined ? pathsData : pathsPcData

				if (device().browser.app === undefined) {
					return router.replace(`${pathDataChk.login}?ReturnUrl=${encodeURIComponent(window.location.href)}`)
				}

				return window.open(`${pathDataChk.login}?ReturnUrl=close`)
			}

			if (errorCallback !== undefined) {
				errorCallback(error, variables)
			}

			return false
		},
		onMutate: (variables: URLSearchParams) => {
			if (progress === true) {
				loading.open()
			}

			if (mutateCallback !== undefined) {
				mutateCallback(variables)
			}
		},
		onSettled: () => {
			/* empty */
		},
		onSuccess: (successData: never, variables: never) => {
			if (progress === true) {
				loading.close()
			}

			if (successCallback !== undefined) {
				successCallback(successData, variables)
			}
		}
	})

	const set = useCallback((value?: unknown) => {
		clearTimeout(data.delayFunc)
		data.delayFunc = setTimeout(() => {
			data.flag = false
		}, 500)

		if (data.flag === false) {
			data.flag = true

			useSetApiMutation.mutate(value as never)
		}
	}, [
		data,
		useSetApiMutation
	])

	return {
		...useSetApiMutation,
		set
	}
}

export default useSetApi
