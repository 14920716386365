import HeaderLoginMenuLiView from '@dg/common/components/Pc/Header/HeaderView/HeaderLoginMenuLiView'
import pathsData from '@dg/common/public/json/pc/paths'
import {
	useTranslation
} from 'react-i18next'

interface HeaderLoginMenuViewProps {
	loginMenuSelected: string;
}

const HeaderLoginMenuView = ({
	loginMenuSelected
}: HeaderLoginMenuViewProps) => {
	const {
		t
	} = useTranslation()

	return (
		<nav
			className="h-10 w-full flex-none bg-[#517fef]"
		>
			<ul
				className="mx-auto flex w-pc"
			>
				<HeaderLoginMenuLiView
					link={pathsData.login}
					selected={(loginMenuSelected === `login`)}
				>
					{t(`로그인`)}
				</HeaderLoginMenuLiView>

				<HeaderLoginMenuLiView
					link={pathsData.joinInfo}
					selected={(loginMenuSelected === `join`)}
				>
					{t(`회원 등록(무료)`)}
				</HeaderLoginMenuLiView>

				<HeaderLoginMenuLiView
					link={`${pathsData.registerSeller}?isSellerRegProcess=Y`}
					last
				>
					{t(`출점자 등록`)}
				</HeaderLoginMenuLiView>
			</ul>
		</nav>
	)
}

export default HeaderLoginMenuView
