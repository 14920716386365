import Button from '@dg/common/components/Button'
import Content from '@dg/common/components/Content'
import Layerpopup from '@dg/common/components/Layerpopup'
import Spinner from '@dg/common/components/Spinner'
import Toast from '@dg/common/components/Toast'
import {
	ArrowUpIcon
} from '@heroicons/react/24/outline'
import Head from 'next/head'
import {
	MutableRefObject, PropsWithChildren, ReactNode
} from 'react'

interface LayoutViewProps {
	className: string;
	createFooterFunc: () => ReactNode;
	createHeaderFunc: () => ReactNode;
	deviceInfo: string;
	goTopFunc: () => void;
	layoutRef: MutableRefObject<HTMLDivElement | null>;
	loading: boolean;
	pageTitle: string;
	theme: string;
}

const LayoutView = ({
	layoutRef,
	children,
	className,
	deviceInfo,
	loading,
	pageTitle,
	theme,
	createFooterFunc,
	createHeaderFunc,
	goTopFunc
}: PropsWithChildren<LayoutViewProps>) => {
	const parseAppEnv = JSON.parse(process.env.appEnv ?? `{"npm_package_name":""}`) as {
		npm_package_name: `live` | `member`;
	}

	let mobilePx = `px-5 pt-5`

	if (parseAppEnv.npm_package_name === `live`) {
		mobilePx = `px-6 pt-6`
	}

	return (
		<>
			{
				parseAppEnv.npm_package_name !== `member` ?
					(
						<title>
							{pageTitle}
						</title>
					) :
					(
						<Head>
							<title>
								{pageTitle}
							</title>
						</Head>
					)
			}

			<div
				id="wrap"
				ref={layoutRef}
				className={
					`
						transition-bgColor
						${deviceInfo === `mobile` ? `mobile` : `pc`}
						${
							theme === `dark` ?
								`dark bg-dgdark-white text-dgdark-black` :
								`light bg-dg-white text-dg-black`
						}
						${className}
					`
				}
			>
				{
					deviceInfo !== `` && (
						<>
							{createHeaderFunc()}

							<Content
								className={
									`
										pb-10
										${deviceInfo === `mobile` ? mobilePx : ``}
									`
								}
							>
								{children}
							</Content>

							{createFooterFunc()}
						</>
					)
				}

				{
					deviceInfo === `mobile` && (
						<Button
							onClick={goTopFunc}
							transition={false}
							className="btn-top hide fixed right-4 z-10 size-10 rounded-full border border-solid
								border-dg-600 bg-dg-white/80 p-2 text-dg-600 transition-all
								dark:border-dgdark-600 dark:bg-dgdark-white/80 dark:text-dgdark-600"
						>
							<ArrowUpIcon />

							<span
								className="sr-only"
							>
								Go To Top
							</span>
						</Button>
					)
				}

				{/* <Button
						className="fixed right-4 z-10 bg-dg-blue
							hover:bg-dg-blue/60 dark:bg-dgdark-blue dark:hover:bg-dgdark-blue/60"
						onClick={() => changeThemeFunc()}
					>
						{(pageData.theme === `dark`) ? `🌛` : `🌞`}
						{` `}
						Mode
					</Button> */}

				{
					loading === true && (
						<Spinner
							hidden={false}
							id="loading-common"
							fixed
						/>
					)
				}

				{
					deviceInfo !== `` && (
						<>
							<Layerpopup
								id="layerpopup-alert"
								defaultFooter
							/>

							<Toast
								id="toast-alert"
							/>
						</>
					)
				}
			</div>
		</>
	)
}

export default LayoutView
