'use client'

import LayoutView from '@dg/common/components/Layout/LayoutView/LayoutView'
import Footer from '@dg/common/components/Mobile/Footer'
import {
	AppHeader as Header
} from '@dg/common/components/Mobile/Header'
import FooterPc from '@dg/common/components/Pc/Footer'
import {
	AppHeader as HeaderPc
} from '@dg/common/components/Pc/Header'
import {
	affiliateApi
} from '@dg/common/lib/api'
import {
	addCookie, device, getFooterType, getHeaderType, getPageTitle
} from '@dg/common/lib/common'
import useAppSetApi from '@dg/common/lib/hooks/useAppSetApi'
import useLogin from '@dg/common/lib/hooks/useLogin'
import * as qoo10Common from '@dg/common/lib/qoo10Common'
import {
	appHeaderTitle, appHeaderBtnFunc, appFooterTabFunc
} from '@dg/common/lib/qoo10Common'
import {
	commonData, commonLoadingValue
} from '@dg/common/lib/store'
import localeJa from '@dg/common/public/locales/ja/common.json'
import i18n from 'i18next'
import {
	useAtom
} from 'jotai'
import localeJaLive from 'live/public/locales/ja/common.json'
import localeJaMember from 'member/public/locales/ja/common.json'
import {
	usePathname, useRouter, useSearchParams
} from 'next/navigation'
import {
	PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState
} from 'react'

declare global {
	interface Window {

		/* Device Token 가져오는 함수 */
		setMobileDeviceTokenFunc: (value: string) => void;
	}
}

interface LayoutProps {
	className?: string;
}

interface LayoutDataProps {
	mobileFooterType: `empty` | `navOnly` | `sub` | `subOnly`;
	mobileHeaderType: `empty` | `headerOnly` | `headerOnlyAppBanner` | `main` | `sub` | `subAppBanner`;
	pcFooterType: `empty` | `sub`;
	pcHeaderType: `empty` | `headerOnly` | `logoOnly` | `main` | `sub`;
}

const Layout = ({
	children, className = ``
}: PropsWithChildren<LayoutProps>) => {
	const parseAppEnv = JSON.parse(process.env.appEnv ?? `{"npm_package_name":""}`) as {
		npm_package_name: `live` | `member`;
	}

	i18n.addResourceBundle(`ja`, `translation`, localeJa)

	if (parseAppEnv.npm_package_name === `member`) {
		i18n.addResourceBundle(`ja`, `translation`, localeJaMember)
	} else if (parseAppEnv.npm_package_name === `live`) {
		i18n.addResourceBundle(`ja`, `translation`, localeJaLive)
	}

	const layoutRef = useRef<HTMLDivElement>(null)

	const [
		,
		setAppToken
	] = useAtom(commonData(`appToken`))

	const [
		deviceInfo,
		setDeviceInfo
	] = useAtom(commonData(`deviceInfo`))

	const [
		title
	] = useAtom(commonData(`title`))

	const [
		loading
	] = useAtom(commonLoadingValue)

	const router = useRouter()
	const pathname = usePathname()
	const searchparams = useSearchParams()
	const login = useLogin()

	let pageName = getPageTitle(pathname, searchparams)

	let pageTitle = `${pageName} - ${title}`

	if (pageName === undefined) {
		pageName = `${title} - ネット通販｜eBay Japan`
		pageTitle = pageName
	}

	const data = useMemo(() => ({
		appTabBar: `` as `N` | `Y`,
		delayAppFooterChkFunc: null as unknown as ReturnType<typeof setTimeout>,
		delayLoginChkFunc: null as unknown as ReturnType<typeof setTimeout>,
		delayRouteChkFunc: null as unknown as ReturnType<typeof setTimeout>,
		delayRouterHandleFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	const [
		pageData,
		setPageData
	] = useState({
		theme: ``
	})

	const loginChkFunc = useCallback(() => {
		const jwtCookieName = `jwtaccess=`
		const jwtLogoutCookieName = `jwtlogout=`

		if (
			document.cookie?.match(jwtCookieName) !== null &&
			document.cookie?.split(jwtCookieName)[1]?.split(`;`)[0] !== `` &&
			login.info === false
		) {
			login.set({
				flag: true,
				userData: document.cookie?.split(jwtCookieName)[1].split(`;`)[0]
			})
		} else if (
			document.cookie?.match(jwtCookieName) === null ||
			document.cookie?.match(jwtLogoutCookieName) !== null
		) {
			login.set({
				flag: false,
				userData: ``
			})
		}
	}, [
		login
	])

	const headerChkFunc = useCallback(() => {
		const url = new URL(window.location.href)

		if (url.protocol !== `https:`) {
			return router.replace(url.href.replace(/http:/gim, `https:`))
		}

		if (device().browser.app === undefined) {
			return getHeaderType(pathname)
		}

		appHeaderTitle(pageName, login.info)

		let headerRightChk: `D` | `P` = `D`

		if (getHeaderType(pathname).match(/headerOnly/) !== null) {
			headerRightChk = `P`
		}

		appHeaderBtnFunc(headerRightChk)

		clearTimeout(data.delayLoginChkFunc)
		data.delayLoginChkFunc = setTimeout(() => {
			loginChkFunc()
		}, 100)

		return `empty`
	}, [
		data,
		login.info,
		loginChkFunc,
		pageName,
		pathname,
		router
	])

	const footerChkFunc = useCallback((forceUpdate = false) => {
		const url = pathname.split(`/`)

		if (device().browser.app === undefined) {
			return getFooterType(pathname)
		}

		const memberappFooterChk = getFooterType(pathname) === `navOnly` && url[2] !== `find`
		const liveappFooterChk = getFooterType(pathname) !== `empty`

		const footerTabChk: `Y` | `N` =
			memberappFooterChk === true ||
			liveappFooterChk === true ?
				`Y` :
				`N`

		if (data.appTabBar !== footerTabChk || forceUpdate === true) {
			data.appTabBar = footerTabChk

			clearTimeout(data.delayAppFooterChkFunc)
			data.delayAppFooterChkFunc = setTimeout(() => {
				appFooterTabFunc(footerTabChk)
			}, 100)
		}

		if (getFooterType(pathname) === `sub`) {
			return `subOnly`
		}

		return `empty`
	}, [
		data,
		pathname
	])

	const changeThemeFunc = useCallback((loaded = false) => {
		let chgTheme = window.localStorage.getItem(`appTheme`) === `dark` ? `light` : `dark`

		if (loaded === true) {
			chgTheme =
				window.localStorage.getItem(`appTheme`) === null ||
				window.localStorage.getItem(`appTheme`) === `light` ?
					`light` :
					`dark`
		}

		/* 안드로이드 앱 키보드 노출 시 하단 fixed 영역 안올라오게 처리
		if (device().browser.app !== undefined) {
			if (device().browser.android === true) {
				if (
					typeof window !== `undefined` &&
					typeof window.setSoftInputMode !== `undefined`
				) {
					window.setSoftInputMode(true)
				}
			}
		}
		*/

		setPageData({
			...pageData,
			theme: chgTheme
		})

		window.localStorage.setItem(`appTheme`, chgTheme)
	}, [
		pageData
	])

	const goTopFunc = useCallback(() => {
		window.scrollTo({
			behavior: `smooth`,
			left: 0,
			top: 0
		})
	}, [])

	const createHeaderFunc = useCallback(() => (
		deviceInfo === `mobile` ?
			(
				<Header
					title={pageName}
					type={headerChkFunc() as LayoutDataProps[`mobileHeaderType`]}
				/>
			) :
			(
				<HeaderPc
					title={pageName}
					type={headerChkFunc() as LayoutDataProps[`pcHeaderType`]}
				/>
			)
	), [
		deviceInfo,
		headerChkFunc,
		pageName
	])

	const createFooterFunc = useCallback(() => (
		deviceInfo === `mobile` ?
			(
				<Footer
					type={footerChkFunc()}
				/>
			) :
			(
				<FooterPc
					type={footerChkFunc() as LayoutDataProps[`pcFooterType`]}
				/>
			)
	), [
		deviceInfo,
		footerChkFunc
	])

	const scrollBeforeFunc = useCallback(() => {
		const scrollY = Math.floor(window.scrollY)

		const btnTopObj = document.querySelector(`#wrap .btn-top`) as HTMLButtonElement

		if (
			(document.querySelector(`body`) as HTMLBodyElement).scrollHeight > window.innerHeight + 100 &&
			scrollY > 100
		) {
			if (btnTopObj?.classList.contains(`hide`) === true) {
				btnTopObj.classList.remove(`hide`)
			}
		} else if (btnTopObj?.classList.contains(`hide`) === false) {
			btnTopObj.classList.add(`hide`)
		}
	}, [])

	const resizeFunc = useCallback(() => {
		if (layoutRef.current !== null) {
			const mobile = device().browser.mobile === true
			const themeChk = mobile === true && pathname.split(`/`)[1] === ``
			let deviceChk = themeChk === true || pathname.split(`/`)[1] === `mobile` ? `mobile` : `pc`

			const fixPcVersionCookieName = `FixPcVersion=`

			if (document.cookie.match(fixPcVersionCookieName) !== null) {
				deviceChk = `pc`
			}

			setDeviceInfo(deviceChk)

			const layout = {
				adminBanner: document.querySelector(`#admin-banner`) as HTMLElement,
				appBanner: document.querySelector(`#app-banner`) as HTMLElement,
				content: document.querySelector(`#wrap-content`) as HTMLElement,
				footer: document.querySelector(`#wrap-footer`) as HTMLElement,
				footerInfo: document.querySelector(`#footer-info`) as HTMLElement,
				header: document.querySelector(`#wrap-header`) as HTMLElement
			}

			const {
				adminBanner, appBanner, content, footer, footerInfo, header
			} = layout

			if (content !== null) {
				const AdminBannerValue = adminBanner !== null ? adminBanner.offsetHeight : 0

				const AppBannerValue = appBanner !== null ? appBanner.offsetHeight : 0

				const headerValue =
					header !== null ? header.offsetHeight + parseFloat(window.getComputedStyle(header).marginBottom) : 0

				const footerValue =
					footer !== null ? footer.offsetHeight + parseFloat(window.getComputedStyle(footer).marginTop) : 0

				const footerUlValue =
					footer?.querySelector(`ul`) ?
						(footer?.querySelector(`ul`) as HTMLUListElement).offsetHeight :
						0

				const footerInfoValue =
					footerInfo !== null ? footerInfo.offsetHeight + parseFloat(window.getComputedStyle(footerInfo).marginTop) - (footerValue - footerUlValue) : 0

				const contentHeight =
					window.innerHeight - headerValue - footerValue - footerInfoValue - AppBannerValue - AdminBannerValue

				let contentPadding = 0

				content.style.minHeight = ``
				content.style.paddingBottom = ``

				contentPadding = parseFloat(window.getComputedStyle(content).paddingBottom) + (device().browser.app !== undefined ? 100 : 0) + (device().browser.mobile !== undefined && footerInfo === null ? footerValue : 0)

				content.style.minHeight = `${contentHeight}px`
				content.style.paddingBottom = `${contentPadding}px`
			}
		}
	}, [
		pathname,
		setDeviceInfo
	])

	const documentVisibilityFunc = useCallback(() => {
		if (document.hidden === false) {
			footerChkFunc(true)
		}
	}, [
		footerChkFunc
	])

	const affiliateDataApi = useAppSetApi({
		api: affiliateApi
	})

	const handleRouteChange = useCallback(() => {
		const url = new URL(window.location.href)

		// __developer=Y
		let developer = ``

		if (url.searchParams.get(`__developer`) !== null) {
			developer = url.searchParams.get(`__developer`) ?? ``
		}

		if (developer === `Y`) {
			addCookie(`__developer=Y`, -1)
		}

		// 제휴 ID 처리
		let jaehuId = ``

		if (url.searchParams.get(`jaehuid`) !== null) {
			jaehuId = url.searchParams.get(`jaehuid`) ?? ``
		}

		const jaehuIdCookieName = `frontjaehuid=`

		const cookieJaehuId = document.cookie.match(jaehuIdCookieName) !== null ? atob(decodeURIComponent(document.cookie.split(jaehuIdCookieName)[1].split(`;`)[0]).replace(/"/gim, ``)) : ``

		if (jaehuId !== `` || cookieJaehuId === ``) {
			if (jaehuId !== cookieJaehuId) {
				const formData = new URLSearchParams()

				formData.append(`value`, jaehuId)

				affiliateDataApi.set(formData)
			}
		}
	}, [
		affiliateDataApi
	])

	useEffect(() => {
		device()

		loginChkFunc()

		const fixPcVersionCookieName = `FixPcVersion=`

		if (pageData.theme === ``) {
			handleRouteChange()

			addCookie(`${fixPcVersionCookieName}Y`, 0)

			changeThemeFunc(true)
		}

		let adminChk = false

		const dgAdminCookieName = `AdminLogin=`

		if (document.cookie.match(dgAdminCookieName) !== null) {
			adminChk = document.cookie.split(dgAdminCookieName)[1].split(`;`)[0] === `Y`
		}

		if (adminChk === false) {
			const urlData = window.location.href || pathname

			if (document.cookie.match(fixPcVersionCookieName) !== null) {
				if (urlData.match(/\/mobile\//gim) !== null) {
					clearTimeout(data.delayRouteChkFunc)
					data.delayRouteChkFunc = setTimeout(() => {
						router.replace(urlData.replace(/\/mobile\//gim, `/pc/`))
					}, 100)
				}
			} else {
				const deviceChk1 =
					device().browser.desktop !== undefined && urlData.match(/\/mobile\//gim) !== null

				const deviceChk2 = device().browser.mobile !== undefined && urlData.match(/\/pc\//gim) !== null

				if (deviceChk1 === true) {
					clearTimeout(data.delayRouteChkFunc)
					data.delayRouteChkFunc = setTimeout(() => {
						router.replace(urlData.replace(/\/mobile\//gim, `/pc/`))
					}, 100)
				}

				if (deviceChk2 === true) {
					clearTimeout(data.delayRouteChkFunc)
					data.delayRouteChkFunc = setTimeout(() => {
						if (urlData.match(/join\/emailcomplete/gim) === null) {
							router.replace(urlData.replace(/\/pc\//gim, `/mobile/`))
						}
					}, 100)
				}
			}
		}

		scrollBeforeFunc()

		window.addEventListener(`scroll`, scrollBeforeFunc)

		resizeFunc()

		window.addEventListener(`resize`, resizeFunc)

		document.addEventListener(`visibilitychange`, documentVisibilityFunc)

		window.setMobileDeviceTokenFunc = (value: string) => {
			setAppToken(value)
		}

		for (const item of Object.keys(qoo10Common)) {
			window[item] =
				(
					qoo10Common as Record<string, (...args: unknown[]) => unknown>
				)[item]
		}

		const cleanup = () => {
			window.removeEventListener(`scroll`, scrollBeforeFunc)

			window.removeEventListener(`resize`, resizeFunc)

			document.removeEventListener(`visibilitychange`, documentVisibilityFunc)
		}

		return cleanup
	}, [
		changeThemeFunc,
		data,
		documentVisibilityFunc,
		handleRouteChange,
		loginChkFunc,
		pageData.theme,
		pathname,
		resizeFunc,
		router,
		scrollBeforeFunc,
		setAppToken
	])

	useEffect(() => {
		clearTimeout(data.delayRouterHandleFunc)
		data.delayRouterHandleFunc = setTimeout(() => {
			handleRouteChange()
		}, 100)
	}, [
		data,
		handleRouteChange,
		pathname,
		searchparams
	])

	const props = {
		children,
		className,
		createFooterFunc,
		createHeaderFunc,
		deviceInfo,
		goTopFunc,
		layoutRef,
		loading,
		pageTitle,
		theme: pageData.theme
	}

	return (
		<LayoutView
			{...props}
		/>
	)
}

export default Layout
