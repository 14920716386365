import {
	device
} from '@dg/common/lib/common'
import {
	store, ssrAtom
} from '@dg/common/lib/store'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	QueryCache, QueryClient, QueryClientProvider
} from '@tanstack/react-query'
import {
	ReactQueryDevtools
} from "@tanstack/react-query-devtools"
import {
	AxiosError
} from 'axios'
import {
	Provider
} from 'jotai'
import {
	useHydrateAtoms
} from 'jotai/utils'
import {
	useRouter
} from 'next/router'
import React, {
	useState
} from "react"

interface ProvidersProps {
	children: React.ReactNode;
}

const Providers = ({
	children
}: ProvidersProps) => {
	const router = useRouter()

	const [
		client
	] = useState(
		new QueryClient({
			defaultOptions: {

				/*
				mutations: {
					onError: (error, variables, context) => {
						console.log(error, variables, context)
					},
					onSuccess: (data, variables, context) => {
						console.log(data, variables, context)
					},
				},
				*/
				queries: {
					retry: 0
				}
			},
			queryCache: new QueryCache({
				onError: (error, query) => {
					if (typeof Error !== `undefined` && process.env.NODE_ENV === `development`) {
						console.log(error)
					}

					const queryOptions = query.options as {
						errorCallback: (callBackError: unknown) => void;
					}

					const errorData = error as AxiosError

					if (errorData.response?.status === 401 && queryOptions.errorCallback === undefined) {
						const pathDataChk = device().browser.desktop === undefined ? pathsData : pathsPcData

						if (device().browser.app === undefined) {
							return router.replace(`${pathDataChk.login}?ReturnUrl=${encodeURIComponent(window.location.href)}`)
						}

						return window.open(`${pathDataChk.login}?ReturnUrl=close`)
					}

					if (queryOptions.errorCallback !== undefined) {
						return queryOptions.errorCallback(error)
					}

					return false
				},
				onSuccess: (data, query) => {
					const queryOptions = query.options as {
						successCallback: (callBackData: unknown) => void;
					}

					if (queryOptions.successCallback !== undefined) {
						return queryOptions.successCallback(data)
					}

					return false
				}
			})
		})
	)

	const SsrWrapper = () => {
		useHydrateAtoms([
			[
				ssrAtom,
				`ssrHydration`
			]
		])

		return children
	}

	return (
		<QueryClientProvider
			client={client}
		>
			<Provider
				store={store}
			>
				<SsrWrapper />
			</Provider>

			<ReactQueryDevtools
				initialIsOpen={false}
			/>
		</QueryClientProvider>
	)
}

export default Providers
