import HeaderView from '@dg/common/components/Mobile/Header/HeaderView/HeaderView'
import {
	device, addCookie
} from '@dg/common/lib/common'
import {
	execApp, goAppStore, goAppPageFailStore, gaDimensionFunc
} from '@dg/common/lib/qoo10Common'
import pathsData from '@dg/common/public/json/mobile/paths'
import {
	useRouter
} from 'next/navigation'
import {
	useCallback, useEffect, useState
} from 'react'

interface HeaderProps {
	title?: string;
	type: `empty` | `headerOnly` | `headerOnlyAppBanner` | `main` | `sub` | `subAppBanner`;
}

const Header = ({
	title = ``, type
}: HeaderProps) => {
	const router = useRouter()

	const [
		pageData,
		setPageData
	] = useState<boolean>(false)

	const appBannerLinkFunc = useCallback(() => {
		if (device().browser.iphone === true || device().browser.ipad === true) {
			execApp(`qoo10jp://`, ``, goAppStore)
		} else {
			goAppPageFailStore(``)
		}

		window.ga(`mweb`, `app_banner`, `click`)

		gaDimensionFunc({
			data: {
				ep_event_area: `app_banner`,
				ep_event_label: `click`,
				ep_event_page: `mweb`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})
	}, [])

	const appBannerCloseFunc = useCallback(() => {
		addCookie(`appbanner=false`, 9999)

		setPageData(false)

		window.ga(`mweb`, `app_banner`, `close`)

		gaDimensionFunc({
			data: {
				ep_event_area: `app_banner`,
				ep_event_label: `close`,
				ep_event_page: `mweb`,
				event_name: `click_event`
			},
			event: true,
			url: window.location.href
		})
	}, [])

	const historyBackChk = useCallback(() => {
		const url = new URL(window.location.href).pathname

		if (window.opener !== null && url === `/mobile/login`) {
			window.close()
		} else if (url === `/mobile/join/validate` || url === `/mobile/join/complete`) {
			router.replace(pathsData.home)
		} else {
			router.back()
		}
	}, [
		router
	])

	useEffect(() => {
		const dgCookieName = `appbanner=`

		let setAppBannerValue = false

		if (document.cookie.match(dgCookieName) === null && (type === `main` || type.match(/AppBanner/) !== null)) {
			setAppBannerValue = true
		}

		setPageData(setAppBannerValue)
	}, [
		type
	])

	const props = {
		appBannerCloseFunc,
		appBannerLinkFunc,
		bannerVisible: pageData,
		historyBackChk,
		title,
		type
	}

	return (
		<HeaderView
			{...props}
		/>
	)
}

export default Header
